/* eslint-disable */
import React from 'react'

const HireMe = () => {
  return (
    <>
    <section className="section py-5" id='hireme' style={{backgroundColor : '#004c4c'}}>
        <div className="container text-center">
            <h2 className="text-light font-weight-bold">Available For FreeLance</h2>
            <a href="#contact" ><button className="btn btn-outline-danger" style={{color : 'white'}}>Contact Me</button></a>
        </div>
    </section>
    </>
  )
}

export default HireMe