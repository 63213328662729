/* eslint-disable */
import React from 'react'

const Footer = () => {
    return (
        <>
            <footer className="footer py-3" id='footer'>
                
            </footer>
        </>
    )
}

export default Footer