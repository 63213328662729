/* eslint-disable */
import React from 'react'

const Articles = () => {
    return (
        <>
            <section className="section" id="articles">
                <div className="container">
                    <h2 className="mb-5 no-select">Articles</h2>
                    <div className="row" style={{padding: '0px' , margin: '0px'}}>

                    <div className="blog-card">
                            <div className="img-holder">
                                <img src="https://miro.medium.com/v2/resize:fit:1400/format:webp/1*sEekPZLUZK0BC8JGfhYSbg.png" alt="Sass" />
                            </div>
                            <div className="content-holder">
                                <h6 className="title">Advanced Styling with Sass</h6>
                                <p className="post-details">
                                    <span>By: Kasun Harshana</span>
                                </p>
                                <p>Here, we are discussing advanced styling with Sass, which can help you improve the efficiency of web design when writing custom SCSS…</p>
                                <a href="https://medium.com/@khguruge/advanced-styling-with-sass-7bbbba978e01" className="read-more">Read more <i className="ti-angle-double-right"></i></a>
                            </div>
                        </div>

                        <div className="blog-card">
                            <div className="img-holder">
                                <img src="https://miro.medium.com/v2/resize:fit:1400/format:webp/1*DRFk8Z4qzxhU7yk9EPgckw.png" alt="DEV-CONTAINERS" />
                            </div>
                            <div className="content-holder">
                                <h6 className="title">WHY DEV-CONTAINERS?</h6>
                                <p className="post-details">
                                    <span>By: Kasun Harshana</span>
                                </p>
                                <p>A Development Container (Dev Container) allows you to use a container as a full-featured development environment. It can be used to run an…</p>
                                <a href="https://medium.com/@khguruge/why-dev-containers-e2acc0d90239" className="read-more">Read more <i className="ti-angle-double-right"></i></a>
                            </div>
                        </div>

                        <div className="blog-card">
                            <div className="img-holder">
                                <img src="https://miro.medium.com/v2/resize:fit:1400/format:webp/1*C-gf2b522geZaO6T7Nb53A.png" alt="Sass Mixins 101" />
                            </div>
                            <div className="content-holder">
                                <h6 className="title">Sass Mixins 101: Simplifying Your Styles with Ease</h6>
                                <p className="post-details">
                                    <span>By: Kasun Harshana</span>
                                </p>
                                <p>In Sass, a mixin is a way to group together a set of CSS rules and then reuse those rules throughout your stylesheet. Mixins are defined…</p>
                                <a href="https://medium.com/@khguruge/sass-mixins-101-simplifying-your-styles-with-ease-816abc4ecd48" className="read-more">Read more <i className="ti-angle-double-right"></i></a>
                            </div>
                        </div>

                        <div className="blog-card">
                            <div className="img-holder">
                                <img src="https://miro.medium.com/v2/resize:fit:1400/format:webp/1*lAUuAWuT6GM9g-x4Q9oOMw.png" alt="TypeScript" />
                            </div>
                            <div className="content-holder">
                                <h6 className="title">Why We Need to Choose TypeScript Over JavaScript</h6>
                                <p className="post-details">
                                    <span>By: Kasun Harshana</span>
                                </p>
                                <p>TypeScript can help prevent bugs in your code, which you would typically handle by writing tests and manual checks. In large JavaScript p…</p>
                                <a href="https://medium.com/@khguruge/why-we-need-to-choose-typescript-over-javascript-6381cf4d9a97" className="read-more">Read more <i className="ti-angle-double-right"></i></a>
                            </div>
                        </div>

                        <div className="blog-card">
                            <div className="img-holder">
                                <img src="https://miro.medium.com/v2/resize:fit:1400/format:webp/1*nYhFgxQhNubFprZ-8oUwKA.png" alt="svelte" />
                            </div>
                            <div className="content-holder">
                                <h6 className="title">Why svelte</h6>
                                <p className="post-details">
                                    <span>By: Kasun Harshana</span>
                                </p>
                                <p>Svelte is a modern JavaScript framework used to build static web apps that are fast, lean, and are fun for developers to use. You can use…</p>
                                <a href="https://medium.com/@khguruge/why-svelte-986ee82dcebc" className="read-more">Read more <i className="ti-angle-double-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Articles