/* eslint-disable */
import React from 'react'

const Resume = () => {
    return (
        <>
            <section className="section container no-select" id="resume">
                <h2 className="mb-5">My Resume</h2>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <div className="mt-2">
                                    <h4>Experience</h4>
                                    <span className="line"></span>
                                </div>
                            </div>
                            <div className="card-body">
                                <h6 className="title text-danger">SOFTWARE ENGINEER</h6>
                                <a href="https://revox.io/" className='fs-14' style={{ paddingBottom: '10px', marginBottom: '15px', fontWeight: 'bold' }}>Revox Pvt Ltd</a>
                                <p style={{ paddingBottom: '0px', marginBottom: '5px' , marginTop: '10px' }}>2022 January - present</p>
                                <ul>
                                    <li>
                                        <p className="desc">
                                            Skilled in creating user-friendly interfaces for websites and apps using Angular and React.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="desc">
                                            Proficient in using Node.js to build strong backend systems.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="desc">
                                            Able to manage databases like MySQL, MongoDB, and Firebase effectively.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="desc">
                                            Experience with Google Cloud Platform (GCP) and AWS for deploying and managing applications online.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="desc">
                                            Experience as a Scrum Master for efficient project management.
                                        </p>
                                    </li>
                                    <li>
                                        <p className='desc'>
                                            Worked together on different website projects, helping the team achieve its goals.
                                        </p>
                                    </li>

                                </ul>
                                <hr />
                                <h6 className="title text-danger">ASSOCIATE SOFTWARE ENGINEER</h6>
                                <a href="https://revox.io/" className='fs-14' style={{ paddingBottom: '10px', marginBottom: '15px', fontWeight: 'bold' }}>Revox Pvt Ltd</a>

                                <p style={{ paddingBottom: '0px', marginBottom: '5px',marginTop: '10px' }}>2021 July - 2021 December</p>
                                <ul>
                                    <li>
                                        <p className="desc">
                                            Worked as a full-stack developer.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="desc">
                                            Developed websites using the MEAN stack, handling both front-end and back-end tasks.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="desc">
                                            Helped develop tech solutions by working together in a team on specific tasks
                                        </p>
                                    </li>
                                </ul>
                                <hr />
                                <h6 className="title text-danger">SOFTWARE ENGINEER - INTERN</h6>
                                <a href="https://www.xdoto.io/" className='fs-14' style={{ paddingBottom: '10px', marginBottom: '15px', fontWeight: 'bold' }}>X.O Concepts Pvt Ltd</a>

                                <p style={{ paddingBottom: '0px', marginBottom: '5px'.at,marginTop: '10px' }}>2020 October - 2021 April</p>
                                <ul>
                                    <li>
                                        <p className="desc">
                                            Created user-friendly interfaces for websites using Vuejs.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="desc">
                                            Worked with MySQL and PHP for managing the backend and databases.
                                        </p>
                                    </li>
                                    <li>
                                        <p className='desc'>
                                            Contributed to several projects, collaborating with teams to achieve successful outcomes.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <div className="mt-2">
                                    <h4>Technical Exposure</h4>
                                    <span className="line"></span>
                                </div>
                            </div>
                            <div className="card-body" style={{ paddingTop: '4px' }} >
                                <ul>
                                    <li>JavaScript</li>
                                    <li>Typescript</li>
                                    <li>NodeJS</li>
                                    <li>Angular</li>
                                    <li>React</li>
                                </ul>
                                <hr />

                                <ul>
                                    <li>MongoDB</li>
                                    <li>MySQL</li>
                                    <li>Firebase</li>
                                </ul>
                                <hr />
                                <ul>
                                    <li>Docker</li>
                                    <li>GCP</li>
                                    <li>AWS</li>

                                </ul>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <div className="mt-2">
                                    <h4>Education</h4>
                                    <span className="line"></span>
                                </div>
                            </div>
                            <div className="card-body">
                                <h6 className="title text-danger">B.Sc. in Computer Science</h6>
                                <span>University of Colombo School of Computing</span>
                                <p>Year of Graduation: 2021</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Resume