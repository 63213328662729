import './App.scss';
import Navbar from './components/Navbar/Navbar';
import About from './components/About/About';
import Resume from './components/Resume/Resume';
import HireMe from './components/HireMe/HireMe';
import Articles from './components/Articles/Articles';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Contact from './components/Contact/Contact';

function App() {
  return (
    <>
      <div id="home">
        <Header />
        <Navbar />
        <About  />
        <Resume />
        <HireMe />
        <Articles />
        <Contact/>
        <Footer />
      </div>
    </>
  );
}

export default App;
