/* eslint-disable */
import React from 'react'

const DownloadButton = () => {
    const handleDownload = () => {
        // Assuming your PDF is in the assets folder
        const pdfPath = require('../CV/CV.pdf');

        // Create a virtual link element
        const link = document.createElement('a');
        link.href = pdfPath;

        // Set the download attribute with the desired file name
        link.download = 'Kasun_CV.pdf';

        // Append the link to the body
        document.body.appendChild(link);

        // Trigger the click event to start the download
        link.click();

        // Remove the link from the DOM
        document.body.removeChild(link);
    };

    return (
        <>
            <button onClick={handleDownload} className="btn btn-primary btn-rounded">
                <i className="ti-printer pr-2"></i>Print Resume
            </button>
        </>
    );
};

const Header = () => {
    return (
        <>
            <header className="header no-select">
                <div className="container">


                    {/* <ul className="social-icons pt-3">
                        <li className="social-item"><a className="social-link text-light" href="#"><i className="ti-facebook" aria-hidden="true"></i></a></li>
                        <li className="social-item"><a className="social-link text-light" href="#"><i className="ti-twitter" aria-hidden="true"></i></a></li>
                        <li className="social-item"><a className="social-link text-light" href="#"><i className="ti-google" aria-hidden="true"></i></a></li>
                        <li className="social-item"><a className="social-link text-light" href="#"><i className="ti-instagram" aria-hidden="true"></i></a></li>
                        <li className="social-item"><a className="social-link text-light" href="#"><i className="ti-github" aria-hidden="true"></i></a></li>
                    </ul> */}
                    <div className="header-content">
                        <h4 className="header-subtitle" >Hello, I am</h4>
                        <h1 className="header-title">Kasun Harshana</h1>
                        <h6 className="header-mono" >Software Engineer | Full Stack Developer</h6>
                        {DownloadButton()}
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header