/* eslint-disable */
import React, { useEffect, useState } from 'react';

const Navbar = () => {
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      for (const section of sections) {
        const element = document.getElementById(section);
        if (element) {
          const offset = element.offsetTop;
          if (scrollPosition >= offset-100) {
            setActiveSection(section);
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array to ensure the effect runs only once

  const sections = ['home', 'about', 'resume', 'hireme', 'articles', 'contact'];

    return (
        <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-white no-select" data-spy="affix" data-offset-top="510">
            <div className="container">
                <button className="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse mt-sm-20 navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item">
                            <a href="#home" className={`nav-link ${activeSection === 'home' ? 'active' : ''}`}>Home</a>
                        </li>
                        <li className="nav-item">
                            <a href="#about" className={`nav-link ${activeSection === 'about' ? 'active' : ''}`}>About</a>
                        </li>
                        <li className="nav-item">
                            <a href="#resume" className={`nav-link ${activeSection === 'resume' ? 'active' : ''}`}>Resume</a>
                        </li>
                    </ul>
                    <ul className="navbar-nav brand">
                        <img src="/front.jpg" alt="" className="brand-img" />
                        <li className="brand-txt">
                            <h5 className="brand-title">Kasun Harshana</h5>
                            <div className="brand-subtitle">Software Engineer | Full Stack Developer</div>
                        </li>
                    </ul>
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a href="#hireme" className={`nav-link ${activeSection === 'hireme' ? 'active' : ''}`}>HireMe</a>
                        </li>
                        <li className="nav-item">
                            <a href="#articles" className={`nav-link ${activeSection === 'articles' ? 'active' : ''}`}>Articles</a>
                        </li>
                        <li className="nav-item">
                            <a href="#contact" className={`nav-link ${activeSection === 'contact' ? 'active' : ''}`}>Contact</a>
                        </li>

                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar