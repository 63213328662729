/* eslint-disable */
import React from 'react'


const DownloadButton = () => {
    const handleDownload = () => {
        // Assuming your PDF is in the assets folder
        const pdfPath = require('../CV/CV.pdf');

        // Create a virtual link element
        const link = document.createElement('a');
        link.href = pdfPath;

        // Set the download attribute with the desired file name
        link.download = 'Kasun_CV.pdf';

        // Append the link to the body
        document.body.appendChild(link);

        // Trigger the click event to start the download
        link.click();

        // Remove the link from the DOM
        document.body.removeChild(link);
    };

    return (
        <>
        <button onClick={handleDownload} className="btn btn-outline-danger"><i className="icon-down-circled2 no-select"></i>Download My CV</button>
        </>
    );
};

const About = () => {
    return (
        <>
            <div className="container-fluid" id="about" data-spy="scroll" data-target=".navbar" >
                <div className="row about-section">
                    <div className="col-lg-6 about-card">
                        <h3 className="font-weight-light no-select">Who am I ?</h3>
                        <span className="line mb-5"></span>
                        <h5 className="mb-3 no-select">Software Engineer / Full stack developer</h5>
                        <p className="mt-20 no-select">I'm a passionate software engineer from Sri Lanka with more than 4 years of experience in building innovative solutions. I specialize in JavaScript, TypeScript, Node.js, React, and Angular. My journey in the tech industry has been driven by a curiosity to explore new technologies.</p>
                        {DownloadButton()}
                    </div>
                    <div className="col-lg-6 about-card">
                        <h3 className="font-weight-light no-select">Personal Info</h3>
                        <span className="line mb-5"></span>
                        <ul className="mt40 info list-unstyled">
                            <li><span>Email</span> : khguruge@gmail.com</li>
                            <li><span>Phone</span> : + (94) 778358231</li>

                            <li>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <a href="https://www.linkedin.com/in/khguruge"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#008080" className="bi bi-linkedin" viewBox="0 0 16 16">
                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                                    </svg></a>
                                    <a className='ml-2' href="https://github.com/khguruge"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#008080" className="bi bi-github" viewBox="0 0 16 16">
                                        <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8" />
                                    </svg></a>

                                    <a className='ml-2' href="https://medium.com/@khguruge"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="28px" height="28px">    <path d="M45,4H5C4.448,4,4,4.448,4,5v40c0,0.552,0.448,1,1,1h40c0.552,0,1-0.448,1-1V5C46,4.448,45.552,4,45,4z M40,13.5 l-1.821,2.197C38.064,15.811,38,15.965,38,16.125V32.75c0,0.16,0.064,0.314,0.179,0.428L40,35.546V36H30v-0.454l2.821-2.368 C32.936,33.064,33,32.91,33,32.75V17.879L24.848,36h-0.001h-1.543l0,0L15,18.375v13.108c0,0.22,0.076,0.433,0.215,0.605L18,35.546 V36h-8v-0.454l2.783-3.438C12.923,31.936,13,31.721,13,31.5V16.388c0-0.142-0.05-0.279-0.142-0.388L11,13.5V13h7.097l7.624,16.183 L33.002,13H40V13.5z" fill="#008080" /></svg>
                                    </a>
                                </div>
                            </li>

                        </ul>
                        <ul className="social-icons pt-3">
                            <li className="social-item"><a className="social-link" href="#"><i className="ti-facebook" aria-hidden="true"></i></a></li>
                            <li className="social-item"><a className="social-link" href="#"><i className="ti-twitter" aria-hidden="true"></i></a></li>
                            <li className="social-item"><a className="social-link" href="#"><i className="ti-google" aria-hidden="true"></i></a></li>
                            <li className="social-item"><a className="social-link" href="#"><i className="ti-instagram" aria-hidden="true"></i></a></li>
                            <li className="social-item"><a className="social-link" href="#"><i className="ti-github" aria-hidden="true"></i></a></li>
                        </ul>
                    </div>
                    {/* <div className="col-lg-4 about-card">
                        <h3 className="font-weight-light">My Expertise</h3>
                        <span className="line mb-5"></span>
                        <div className="row">
                            <div className="col-1 text-danger pt-1"><i className="ti-widget icon-lg"></i></div>
                            <div className="col-10 ml-auto mr-3">
                                <h6>UX Design</h6>
                                <p className="subtitle"> exercitat Repellendus,  corrupt.</p>
                                <hr />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-1 text-danger pt-1"><i className="ti-paint-bucket icon-lg"></i></div>
                            <div className="col-10 ml-auto mr-3">
                                <h6>Web Development</h6>
                                <p className="subtitle">Lorem ipsum dolor sit consectetur.</p>
                                <hr />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-1 text-danger pt-1"><i className="ti-stats-up icon-lg"></i></div>
                            <div className="col-10 ml-auto mr-3">
                                <h6>Digital Marketing</h6>
                                <p className="subtitle">voluptate commodi illo voluptatib.</p>
                                <hr />
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default About